/* resets */
.form,
.form * {
  box-sizing: border-box;
}
.form button {
  cursor: pointer;
}
.form__ul-reset {
  list-style: none;
  margin: 0;
  padding: 0;
  /* if unset is supported */
  margin: unset;
  padding: unset;
}
.form__btn-reset {
  outline: none;
  border: none;
  background: none;
  padding: 0;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-align: left;
  /* if unset is supported */
  outline: unset;
  border: unset;
  background: unset;
  padding: unset;
  color: unset;
  font-size: unset;
  font-weight: unset;
  text-align: unset;
}
.form__input-reset {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  font: inherit;
  /* if unset is supported */
  background: unset;
  border: unset;
  outline: unset;
  padding: unset;
  margin: unset;
  font: unset;
}

/* vars */
.form {
  --form-color-error: red;
  --form-color-gray: #dedede;
  --form-field-height: 46px;
}

.form__fields {
  display: flex;
  align-items: stretch;
}
.form__fields .form__field--type_checkbox,
.form__fields .form__field--type_radio {
  display: flex;
  align-items: center;
}
.form__fields .form__field:not(.form__field--type_checkbox):not(.form__field--type_radio) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form__field {
  flex-basis: 100%;
  margin-bottom: 1em;
}
.form__fields--with_gaps .form__field + .form__field {
  margin-left: 1rem;
}
/* border-collapsing of adjacent fields */
.form__fields--no_gaps .form__field--no_errors + .form__field--no_errors .form__input,
.form__fields--no_gaps .form__field--has_errors + .form__field--has_errors .form__input {
  border-left-color: transparent;
}

.form__label,
.form__field-error {
  font-size: 13px;
  line-height: 1.15em;
  margin-bottom: 8px;
}
.form__label {
  display: inline-block;
}
.form__field-error {
  color: red;
  color: var(--form-color-error, red);
}
.form__label + .form__field {
  margin-top: 10px;
}

.form__input,
.form-select--native,
.form-select__value,
.form-tag-selector__value,
.form-tag-selector__input-wrapper {
  padding: 0.9375em 1.3125em 0.5625em;
  height: 100%;
}
.form__input,
.form__dropdown-wrapper,
.form-select--native {
  border: solid 1px #dedede;
  border-color: var(--form-color-gray, #dedede);
  margin-top: auto; /* vertical alignment within row of form__fields */
}
.form__input--type_date,
.form__input--type_email,
.form__input--type_number,
.form__input--type_password,
.form__input--type_search,
.form__input--type_text,
.form__input--type_url,
.form__dropdown-wrapper,
.form-select--native {
  font-size: 16px;
  line-height: 1.5em;
  height: 46px;
  height: var(--form-field-height, 46px);
}
.form__input--type_textarea {
  min-height: 46px;
  min-height: var(--form-field-height, 46px);
}
.form__input {
  width: 100%;
}
/* .form__field--has_focus .form__input {
  border-color: #17419b;
} */
.form__field--has_errors .form__input {
  border-color: red;
  border-color: var(--form-color-error, red);
}
.form__errors {
  color: red;
  color: var(--form-color-error, red);
  list-style: none;
  margin: 10px 0;
  padding: 0;
}
.form__errors:empty {
  margin: 0;
}
.form__error + .form__error {
  margin-top: 1em;
}

.form__dropdown-wrapper {
  position: relative;
}
.form__dropdown {
  display: none;
  box-shadow: 0px 15px 30px 6px rgba(0,0,0,0.2);
  position: absolute;
  width: 100%;
  z-index: 20;
  background: white;
  left: 0;
  top: calc(100% + 2px); /* for the border-bottom of parent */
  max-height: 33vh;
  overflow: auto;
}
.form__dropdown--no_results {
  padding: 0.33em 1em;
}
.form__dropdown-wrapper--is_open .form__dropdown {
  display: block;
}
.form__dropdown-wrapper--is_open .form-tag-selector__value {
  display: none;
}
.form__dropdown-item {
  line-height: 1.8em;
}
.form__dropdown-item button {
  display: block;
  width: 100%;
  padding: 0.33em 1em;
}
.form__dropdown-item:first-child button {
  padding-top: 0.667em;
}
.form__dropdown-item:last-child button {
  padding-bottom: 0.667em;
}
.form__dropdown-item--is_selected,
.form__dropdown-item:hover button,
.form__dropdown-item button:focus {
  background: #dedede;
  background: var(--form-color-gray, #dedede);
}

.form-select,
.form-tag-selector {
  padding: 0;
}
.form-select__value,
.form-tag-selector__value {
  color: #000;
  width: 100%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: space-between;
}
.form-select__value > *,
.form-tag-selector__value > * {
  overflow: hidden;
  text-overflow: ellipsis;
}
.form-select__value::after,
.form-tag-selector__value::after {
  content: "\02C5";
  font-size: 0.75em;
  transform: scaleX(1.5);
}
.form__field--no_value .form-select__value,
.form__field--no_value .form-tag-selector__value {
  opacity: 0.5;
}
.form-select--disabled .form-select__value,
.form-tag-selector--disabled .form-tag-selector__value {
  opacity: 0.5;
  background: #dedede;
  background: var(--form-color-gray, #dedede);
  cursor: not-allowed;
}
.form-tag-selector__input-wrapper {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  height: 100%;
}
.form-tag-selector__input {
  height: 100%;
  flex-grow: 1;
}
.form-tag-selector__tags {
  display: flex;
  flex-wrap: wrap;
  min-height: 100%;
}
.form-tag-selector__tag {
  background: #dedede;
  background: var(--form-color-gray, #dedede);
  padding: 0 0.5em;
  margin: 0 0.25em 0.1667em;
  border-radius: 0.25em;
  font-size: 0.75em;
  line-height: 1.5em;
}
.form-tag-selector__tag-remove {
  display: inline-block;
  margin-left: 0.25em;
}

.form-password {
  position: relative;
}
.form-password button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  font-size: 0.75em;
  opacity: 0.75;
}
.form-password .form__input {
  padding-right: 40px;
}

.form__button {
  font-family: inherit;
}
.form__checkbox,
.form__radio,
.form__button,
.form__field--type_checkbox .form__label,
.form__field--type_radio .form__label {
  cursor: pointer;
}

.form__submit {
  position: relative;
}
.form__submit--is_loading:after {
  /* insert url here for loading graphic */
  /* background: url() center / contain no-repeat; */

  background-color: inherit;
  position: absolute;
  content: '';
  z-index: 5;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}

.form__fields .form__field {

}

.form__fields--count_2 .form__field {
  max-width: calc((100% - 1rem) / 2);
}
.form__fields--count_3 .form__field {
  max-width: calc((100% - 2rem) / 3);
}
.form__fields--count_4 .form__field {
  max-width: calc((100% - 3rem) / 4);
}
.form__fields--count_5 .form__field {
  max-width: calc((100% - 4rem) / 5);
}
.form__fields--count_6 .form__field {
  max-width: calc((100% - 5rem) / 6);
}
.form__fields--count_7 .form__field {
  max-width: calc((100% - 6rem) / 7);
}
.form__fields--count_8 .form__field {
  max-width: calc((100% - 7rem) / 8);
}
.form__fields--count_9 .form__field {
  max-width: calc((100% - 8rem) / 9);
}
.form__fields--count_10 .form__field {
  max-width: calc((100% - 9rem) / 10);
}
.form__fields--count_11 .form__field {
  max-width: calc((100% - 10rem) / 11);
}
.form__fields--count_12 .form__field {
  max-width: calc((100% - 11rem) / 12);
}

.form__field--all_25 { flex-basis: 25%; }
.form__field--all_50 { flex-basis: 50%; }
.form__field--all_75 { flex-basis: 75%; }
.form__field--all_100 { flex-basis: 100%; }

@media (max-width: 767px) {
  .form__field--m_25 { flex-basis: 25%; }
  .form__field--m_50 { flex-basis: 50%; }
  .form__field--m_75 { flex-basis: 75%; }
  .form__field--m_100 { flex-basis: 100%; }
}
@media (min-width: 768px) {
  .form__field--nm_25 { flex-basis: 25%; }
  .form__field--nm_50 { flex-basis: 50%; }
  .form__field--nm_75 { flex-basis: 75%; }
  .form__field--nm_100 { flex-basis: 100%; }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .form__field--t_25 { flex-basis: 25%; }
  .form__field--t_50 { flex-basis: 50%; }
  .form__field--t_75 { flex-basis: 75%; }
  .form__field--t_100 { flex-basis: 100%; }
}
@media (max-width: 767px), (min-width: 1024px) {
  .form__field--nt_25 { flex-basis: 25%; }
  .form__field--nt_50 { flex-basis: 50%; }
  .form__field--nt_75 { flex-basis: 75%; }
  .form__field--nt_100 { flex-basis: 100%; }
}
@media (min-width: 1024px) {
  .form__field--d_25 { flex-basis: 25%; }
  .form__field--d_50 { flex-basis: 50%; }
  .form__field--d_75 { flex-basis: 75%; }
  .form__field--d_100 { flex-basis: 100%; }
}
@media (max-width: 1023px) {
  .form__field--nd_25 { flex-basis: 25%; }
  .form__field--nd_50 { flex-basis: 50%; }
  .form__field--nd_75 { flex-basis: 75%; }
  .form__field--nd_100 { flex-basis: 100%; }
}
